<template>
  <v-card
    class="mx-auto mb-10"
    width="374"
    elevation="0"
  >
    <v-progress-linear
      :value="percentFromTarget(getMonthProfit(yearMonth))"
      striped
      :indeterminate="loading"
      color="teal lighten-4"
      height="28"
      class="mb-5"
    >
      <div style="position: absolute; left: 4px">
        <v-icon style="transition-duration: 0.2s">
          mdi-currency-rub
        </v-icon>
      </div>
      <span v-if="!loading" style="transition-duration: 0.2s">
          <strong> {{ getMonthProfit(yearMonth) | sum }} </strong> ( + {{
          getMonthSum(yearMonth) | sum
        }} <span></span>/ - {{ getMonthExpense(yearMonth) | sum }} )
        </span>
    </v-progress-linear>

    <v-row justify="center">
      <register-an-expense class="mb-5" @update="updatePays" :text="true"></register-an-expense>
    </v-row>

    <v-row justify="center">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-container>
            <v-row justify="space-between">
              <v-btn
                icon x-large color="teal"
                style="flex: 0 1 20%"
                @click="monthDown()"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-text-field
                v-model="TextMonthYYYYg"
                style="flex: 0 1 60%"
                label="Выберите месяц"
                prepend-icon="mdi-calendar"
                color="teal lighten-1"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
              <v-btn
                icon x-large color="teal"
                style="flex: 0 1 20%"
                @click="monthUp()"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </template>
        <v-date-picker
          v-model="yearMonth"
          @input="menu = false"
          type="month"
          color="teal lighten-1"
          locale="ru-RU"
          width="290"
          no-title
          scrollable
        >
        </v-date-picker>
      </v-menu>
    </v-row>

    <div v-if="!loading && pays.length">
      <div v-for="date in iterableSetOfDates(yearMonth)" :key="date">
        <div v-for="(pay, index) in getPaysFromCurrentPayListForDate(date)" :key=pay._id>
          <v-row
            v-if="index === 0"
            justify="center"
          >
            <div class="font-weight-bold pt-12">{{ date | date }}</div>
          </v-row>

          <div
            style="display: flex!important; justify-content:space-between; align-items:center;"
            v-touch="{right: () => dialogOpenHandler(pay), left: () => dialogOpenHandler(pay)}"
          >
            <div>
              <v-icon
                x-large
                :color="pay.sum > 0 ? 'green darken-2' : 'blue darken-3'"
              >
                {{ pay.sum > 0 ? 'mdi-cash-plus' : 'mdi-cash-minus' }}
              </v-icon>
            </div>
            <div
              v-if="pay.description"
              class="font-weight-light px-4 py-4"
            >
              {{ pay.description }}
            </div>

            <div
              v-if="pay.record"
              class="font-weight-light px-4 py-4"
            >
              {{
                pay.record.firstName
                  ? `${pay.record.firstName} ${pay.record.lastName} ${pay.record.category.description}`
                  : `${pay.record.subscriber.firstName} ${pay.record.subscriber.lastName} ${pay.record.category.description}`
              }}
            </div>

            <div
              v-if="pay.sum > 0"
              class="green--text text--darken-1 font-weight-bold pr-4"
              style="font-size: large; white-space: nowrap"
            >
              + {{ pay.sum | sum }}
            </div>

            <div
              v-if="pay.sum < 0"
              class="blue--text text--darken-2 font-weight-bold pr-4"
              style="font-size: large; white-space: nowrap"
            >
              - {{ (pay.sum * -1) | sum }}
            </div>

          </div>

          <v-divider
            :inset="pay.inset"
          ></v-divider>


        </div>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5 justify-center">
            Удалить платёж?
          </v-card-title>
          <v-card-text class="text-center">
            <div
              :class="currentPay.sum > 0 ? 'green--text': 'blue--text'"
              class="font-weight-bold"
            >
              {{ currentPay.sum | sum }}
            </div>
            <div v-if="currentPay.record" class="font-weight-light">
              {{
                currentPay.record.firstName
                  ? `${currentPay.record.firstName} ${currentPay.record.lastName} ${currentPay.record.category.description}`
                  : `${currentPay.record.subscriber.firstName} ${currentPay.record.subscriber.lastName} ${currentPay.record.category.description}`
              }}
            </div>
            <div v-if="currentPay.description">
              {{ currentPay.description }}
            </div>
          </v-card-text>
          <v-card-actions>

            <v-btn
              color="green darken-1"
              text
              @click="dialog = false"
            >
              Отмена
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click="deletePay(currentPay)"
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-card>
</template>


<script>

import dateFilter from "@/filters/dateFilter";
import RegisterAnExpense from "@/components/RegisterAnExpense";

const MONTHS = [
  'ЯНВАРЬ',
  'ФЕВРАЛЬ',
  'МАРТ',
  'АПРЕЛЬ',
  'МАЙ',
  'ИЮНЬ',
  'ИЮЛЬ',
  'АВГУСТ',
  'СЕНТЯБРЬ',
  'ОКТЯБРЬ',
  'НОЯБРЬ',
  'ДЕКАБРЬ'
]

export default {
  components: {RegisterAnExpense},
  data: () => ({
    yearMonth: '',
    loadedMonths: [],
    loading: true,
    pays: [],
    timeOut: '',
    dialog: false,
    menu: false,
    currentPay: '',
    target: 77777
  }),

  computed: {
    TextMonthYYYYg() {
      return `${this.monthToString(this.yearMonth)} ${this.yearMonth.slice(0, 4)} г.`
    }
  },

  methods: {
    async getPaysForYearMonth(ISOYearMonth) {
      if (this.loadedMonths.includes(ISOYearMonth)) return
      this.loading = true
      if (!ISOYearMonth) return
      let dates = [] // инициализация массива дат
      // пребразуем дату в объект даты
      const date = new Date(ISOYearMonth)
      // получаем начальный месяц
      let month = new Date(ISOYearMonth).getMonth()
      // получаем последний день месяца
      this.lastDate = dateFilter(new Date(date.getFullYear(), date.getMonth() + 1, 0), "ISO")
      // получаем точку останова цикла
      const targetMonth = new Date(new Date(ISOYearMonth).setMonth(new Date(ISOYearMonth).getMonth() - 1)).getMonth()

      while (month !== targetMonth) {
        // пушим дату
        dates.push(this.lastDate)
        // убавляем один день
        this.lastDate = dateFilter(new Date(this.lastDate).setDate(new Date(this.lastDate).getDate() - 1), "ISO")
        // отправляем месяц на проверку цикла
        month = new Date(this.lastDate).getMonth()
      }

      dates = dates.filter(d => {
        return !this.loadedMonths.includes(d.slice(0, 7))
      })

      if (dates.length) {
        this.loading = true
        const json = await this.authFetch('/pay/load-pays-for-dates', {dates})
        const paysToAdd = json.pays.filter(pay => !this.pays.map(p => p._id).includes(pay._id))
        this.pays = [...this.pays, ...paysToAdd].sort(this.sortPaysFunc)
        this.loadedMonths.push(ISOYearMonth)
        this.loading = false
      }
      this.loading = false
    },

    iterableSetOfYearMonths() {
      return Array.from(new Set(this.pays.map(p => p.date.slice(0, 7))))
    },

    iterableSetOfDates(yearMonth) {
      if (!this.pays.length) return
      return Array.from(new Set(this.pays.filter(p => p.date.slice(0, 7) === yearMonth).map(p => p.date)))
    },

    getNextMonth(ISOYearMonth) {
      return dateFilter(
        new Date(new Date(ISOYearMonth).setMonth(new Date(ISOYearMonth).getMonth() - 1)),
        'ISO'
      ).slice(0, 7)
    },

    getPaysFromCurrentPayListForDate(date) {
      return this.pays.filter(p => p.date === date).sort(this.sortPaysFunc)
    },

    dialogOpenHandler(pay) {
      this.currentPay = pay
      this.dialog = true
    },

    longClickHandler(pay) {
      this.timeOut =
        setTimeout(() => {
          this.currentPay = pay
          this.dialog = true
        }, 500)
    },

    clearCurrentTimeout() {
      clearTimeout(this.timeOut)
    },

    monthToString(yearMonth) {
      return MONTHS[+(yearMonth.slice(5)) - 1]
    },

    getMonthSum(yearMonth) {
      return this.pays
        .filter(p => p.date.slice(0, 7) === yearMonth && p.sum > 0)
        .reduce((acc, p) => acc + +p.sum, 0)
    },

    getMonthExpense(yearMonth) {
      return this.pays
        .filter(p => p.date.slice(0, 7) === yearMonth && p.sum < 0)
        .reduce((acc, p) => acc + +p.sum, 0) * -1
    },

    getMonthProfit(yearMonth) {
      return this.pays
        .filter(p => p.date.slice(0, 7) === yearMonth)
        .reduce((acc, p) => acc + +p.sum, 0)
    },

    percentFromTarget(sum) {
      return Math.floor(sum / this.target * 100)
    },

    async deletePay(pay) {
      this.dialog = false
      this.loading = true
      const json = await this.authFetch('/pay/delete-pay-on-id', {_id: pay._id})
      if (json.status === 200) {
        this.pays = this.pays.filter(p => p._id !== pay._id)
        this.currentPay = ''
      }
      this.loading = false
    },

    monthDown() {
      this.yearMonth = dateFilter(new Date(this.yearMonth).setMonth(new Date(this.yearMonth).getMonth() - 1), 'ISO')
        .slice(0, 7)
    },

    monthUp() {
      this.yearMonth = dateFilter(new Date(this.yearMonth).setMonth(new Date(this.yearMonth).getMonth() + 1), 'ISO')
        .slice(0, 7)
    },

    updatePays(pay) {
      this.loading = true
      if (this.pays.map(p => p._id).includes(pay._id)) return
      this.pays.push(pay)
      this.pays.sort(this.sortPaysFunc)
      this.loading = false
    }
  },

  async mounted() {
    this.$route.query.yearMonth
      ? this.yearMonth = this.$route.query.yearMonth
      : this.yearMonth = dateFilter(Date.now(), 'ISO').slice(0, 7)
  },

  watch: {
    yearMonth(newYearMonth) {
      this.getPaysForYearMonth(newYearMonth)
    }
  }
}
</script>
